import React, { useState } from "react";
import * as Icon from "@phosphor-icons/react";

import "./login-form.css";

function LoginForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({ id: 0 });
    const [returnUrl, setReturnUrl] = useState("");

    function logIn(event) {
        event.preventDefault();

        if (validFields()) {
            const loginData = {
                username: username,
                password: password,
                returnUrl: function () {
                    let returnUrl = "";
                    const urlParams = new URLSearchParams(window.location.search);
                    const foundReturnUrl = urlParams.get("ReturnUrl");
                    if (foundReturnUrl != null)
                        returnUrl = foundReturnUrl;

                    return returnUrl;
                }()
            };

            fetch("/account/login", {
                method: "POST",
                body: JSON.stringify(loginData),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "dataType": "json"
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.user)
                    window.location.href = json.returnUrl;
            })
            .catch(error => console.log(error));
        }
        else {
        }
    }

    function validFields() {
        let isValid = true;

        let error = "";
        if (username.trim() === "") {
            error = "Please enter your username.";
            isValid = false;
        }
        else if (password.trim() === "") {
            error = "Please enter your password.";
            isValid = false;
        }

        return isValid;
    }

    return (
        <div styleName="login-background">
            <div styleName="main-container">
                {
                    user.id === 0 &&
                    <div styleName="login-form">
                        <div styleName="website-name">PS Connect</div>
                        <div styleName="login-welcome-msg">
                            <Icon.HandWaving color="#ffb200" weight="fill" size={24} />
                            <h3 styleName="welcome">Welcome!</h3>
                            <p styleName="msg">Login to start messaging.</p>
                        </div>
                        <form onSubmit={logIn}>
                            <div styleName="content">
                                <div styleName="input-field">
                                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} name="username" placeholder="Username" autoFocus />
                                </div>
                                <div styleName="input-field">
                                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} name="password" placeholder="Password" />
                                </div>
                            </div>
                            <div styleName="action">
                                <button type="submit">Login</button>
                            </div>
                            <a href="/account/forgot" styleName="link">Forgot username or password?</a>
                        </form>
                    </div>
                }
            </div>
        </div>
        
    );
}

export default LoginForm