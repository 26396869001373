import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginForm from "./login-form/login-form.jsx";

import "./../../../../Styles/site.css";

function IndexContainer() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/account/login" exact element={<LoginForm />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

const root = createRoot(document.getElementById("root"));
root.render(<IndexContainer />);